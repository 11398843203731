export class ConsumerSmall {
    public id;
    public externalId;
    public gender = 0;
    public lastName = '';
    public firstName = '';
    public mobile = '';
    public email = '';
    public isSponsor = null;
    public sponsorCode = '';
    public createdAt;
    public branchCode: string;
}
