import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './components/header/header.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {RouterModule} from '@angular/router';
import {SearchBarComponent} from './components/search-bar/search-bar.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OperationsBarComponent} from './components/operations-bar/operations-bar.component';
import {MatIconModule} from '@angular/material/icon';
import {MatSortModule} from '@angular/material/sort';
import {MatRippleModule} from '@angular/material/core';
import {MatSidenavModule} from '@angular/material/sidenav';
import {SidenavComponent} from './components/sidenav/sidenav.component';
import {
    MsClientsService,
    MsConsumersService,
    MsDataService,
    MsPartnersService,
    MsServicesGiftService,
    MsServicesOperationsService,
    MsServicesSponsorshipService,
    MsUtilsEmailService
} from '@isifid/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatListModule} from '@angular/material/list';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {BannerComponent} from './components/banner/banner.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {DateBeforePipe} from './pipes/date-before.pipe';

@NgModule({
    declarations: [
        HeaderComponent,
        SearchBarComponent,
        OperationsBarComponent,
        SidenavComponent,
        BannerComponent,
        DateBeforePipe
    ],
    exports: [
        HeaderComponent,
        SearchBarComponent,
        OperationsBarComponent,
        SidenavComponent,
        BannerComponent,
        DateBeforePipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatToolbarModule,
        MatMenuModule,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        MatTableModule,
        MatTooltipModule,
        MatSortModule,
        MatRippleModule,
        MatSidenavModule,
        MatListModule,
        MatDialogModule,
        ReactiveFormsModule,
        RouterModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule
    ],
    providers: [
        MsClientsService,
        MsConsumersService,
        MsDataService,
        MsPartnersService,
        MsServicesGiftService,
        MsServicesOperationsService,
        MsServicesSponsorshipService,
        MsUtilsEmailService
    ]
})
export class SharedModule {
}
