import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {ApiService, Branch, Offer} from '@isifid/core';

import {ConsumerService} from './consumer.service';
import {
    OperationStat,
    SponsoredStat,
    SponsorshipStat,
    SponsorStat,
    StatsDisplay
} from '../../modules/admin/models/stats.model';


@Injectable({
    providedIn: 'root'
})
export class StatsService {
    private readonly endpoint = '/stats';

    constructor(
        private readonly apiService: ApiService,
        private readonly consumerService: ConsumerService
    ) {
    }

    public getSponsorshipStat(operationId, fields?: Array<string>, filters?: object): Observable<SponsorshipStat> {
        return this.apiService.get(`${this.endpoint}/sponsorships/operations/${operationId}`, fields, filters);
    }

    public getByOperationId(operationId, fields?: Array<string>, filters?: object): Observable<OperationStat> {
        return this.apiService.get(`${this.endpoint}/rewards/operations/${operationId}`, fields, filters);
    }

    public convertDateUniversal(date: string): string {
        // Convert string dd/mm/yyyy to yyyy-mm-dd
        if (!date) return '';
        const dateArray = date.split('/');
        if (dateArray.length === 3) return dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
        else return date;
    }

    public convertDateForSpreadsheet(date: string, withTime = false): string {
        if (!date) return '';
        date = date.replace('T', ' ');
        if (withTime) return date.substring(0, 19);
        return date.substring(0, 10);
    }

    public getStatsOperationsColNames(customId = '', level= false, label = []): Array<Array<string>> {
        const colNames = ['Prénom conseiller', 'Nom conseiller', 'Adresse email Conseiller', 'Agence (code)',
            'Prénom client', 'Nom client', 'Date de naissance', customId, 'Mobile', 'Adresse email client', 'Montant de la dotation',
            'Date d’attribution', 'Date d’expiration', 'Nom de l’offre'];
        if (level) colNames.splice(4, 0, 'Agence (nom)', 'Type d\'accès');
        colNames.push(...label);
        return [
            colNames,
            // Consumer Tracking,
            [
                'Adresse email Conseiller', 'Prénom client', 'Nom client', 'Numéro du client', 'Mobile', 'Adresse email client', 'Montant de la dotation',
                'Date d’attribution', 'Date d’expiration', 'Nom de l’offre'
            ]
        ];
    }

    public getTrackingColNames(trackingLevel, budget: boolean): Array<string> {
        let colNames = ['Montant de<br>récompenses consommées'];
        if (budget) {
            colNames = ['Budget dotations<br>(valeur facial)', 'Montant de<br>récompenses consommées', '% consommé<br>du budget',
                'Solde Budget<br>(valeur facial)'];
        }
        switch (trackingLevel) {
            case 'global':
                break;
            case 'sector':
                colNames.unshift('Code secteur', 'Libellé secteur');
                break;
            case 'groupAgency':
                colNames.unshift('Code groupe d\'agence', 'Libellé groupe d\'agence');
                break;
            case 'agency':
                colNames.unshift('Code agence', 'Libellé agence');
                break;
            default:
                break;
        }
        colNames.push('Liste de bénéficiaires</br>concernés<br>(cliquer sur le comptage<br>pour ouvrir la liste nominative)');
        return colNames;
    }

    public getStatsOperationsDisplayedColumns(): Array<Array<string>> {
        return [
            ['giftUserFirstName', 'giftUserLastName', 'giftUserEmail', 'branchCode', 'firstName', 'lastName', 'birthDate', 'externalId', 'mobile', 'email',
                'amount', 'rewardedAt', 'expireAt', 'label'],
            // Consumer Tracking,
            ['giftUserEmail', 'firstName', 'lastName', 'externalId', 'mobile', 'email', 'amount', 'rewardedAt', 'expireAt', 'label']
        ];
    }

    public getTrackingOperationsDisplayedColumns(trackingLevel, budget: boolean): Array<string> {
        let displayedColumns = ['amountExpended'];
        if (budget) displayedColumns = ['amountGranted', 'amountExpended', 'amountExpendedPercent', 'amountRemaining'];
        if (trackingLevel !== 'global') displayedColumns.unshift('entityExternalId', 'entityName');
        displayedColumns.push('export');
        return displayedColumns;
    }

    public getStatsSponsorshipColNames(customId, branchName= false): Array<Array<any>> {
        const colNames = [
            // Aggregated Data
            [],
            // Sponsor
            ['Prénom conseiller', 'Nom conseiller', 'Adresse email Conseiller', 'Agence (code)',
                'Prénom client', 'Nom client', customId, 'Mobile', 'Adresse email client', 'Date envoi code parrain', 'Code parrain',
                'Date de naissance', 'Nombre de filleuls'],
            // SponsorRewarded
            ['Prénom conseiller', 'Nom conseiller', 'Adresse email Conseiller', 'Agence (code)',
                'Prénom client', 'Nom client', customId, 'Mobile', 'Adresse email client', 'Date envoi code parrain', 'Code parrain',
                'Date de naissance', 'Date d\'attribution'],
            // Sponsored
            ['Prénom conseiller', 'Nom conseiller', 'Adresse email Conseiller', 'Agence (code)',
                'Prénom client', 'Nom client', customId, 'Mobile', 'Adresse email client', 'Date d’attribution', 'Code parrain',
                customId + ' du parrain', 'Email parrain', 'Montant de la dotation', 'Date de naissance'],
            // Invites
            ['Prénom client', 'Nom client', 'Adresse email client', 'Mobile', 'Date d\'invitation'],
            // Aggregated giftUser stats
            ['Prénom conseiller', 'Nom conseiller', 'Adresse email Conseiller', 'Agence (code)', 'Nombre de parrains', 'Nombre de filleuls'],
            // Aggregated branches stats
            ['Agence (code)', 'Agence (nom)', 'Nombre de parrains', 'Nombre de filleuls']
        ];
        if (branchName) {
            colNames[1].splice(4, 0, 'Agence (nom)');
            colNames[2].splice(4, 0, 'Agence (nom)');
            colNames[3].splice(4, 0, 'Agence (nom)');
            colNames[5].splice(4, 0, 'Agence (nom)');
        }
        return colNames;
    }

    public getStatsSponsorshipDisplayedColumns() {
        return [
            // Aggregated Data
            [],
            // Sponsor
            ['giftUserFirstName', 'giftUserLastName', 'giftUserEmail', 'branchCode', 'firstName', 'lastName',
                'externalId', 'mobile', 'email', 'sponsorCodeSentAt', 'sponsorCode', 'birthDate', 'sponsoredCount'],
            // SponsorRewarded
            ['giftUserFirstName', 'giftUserLastName', 'giftUserEmail', 'branchCode', 'firstName', 'lastName',
                'externalId', 'mobile', 'email', 'sponsorCodeSentAt', 'sponsorCode', 'birthDate', 'rewardCreatedAt'],
            // Sponsored
            ['giftUserFirstName', 'giftUserLastName', 'giftUserEmail', 'branchCode', 'firstName', 'lastName',
                'externalId', 'mobile', 'email', 'rewardedAt', 'sponsorCode', 'sponsorExternalId', 'sponsorEmail', 'amountRewarded', 'birthDate'],
            // Invites
            ['firstName', 'lastName', 'email', 'mobile', 'createdAt'],
            // Gift users
            ['firstName', 'lastName', 'email', 'branchCode', 'sponsorCount', 'sponsoredCount'],
            // Branches
            ['externalId', 'name', 'sponsorCount', 'sponsoredCount']
        ];
    }

    public formatSponsorStats(stats: SponsorStat[], statsFormatted, anonymizeConsumersData, professionalEnabled?): any {
        for (const stat of stats) {
            if (anonymizeConsumersData) {
                stat.consumerStat.firstName = '';
                stat.consumerStat.lastName = '';
                stat.consumerStat.email = '';
                stat.consumerStat.mobile = '';
                stat.consumerStat.birthDate = '';
                stat.consumerStat.gender = '';
            }

            const statFormatted = {
                giftUserFirstName: '',
                giftUserLastName: '',
                giftUserEmail: '',
                branchCode: '',
                branchName: '',
                firstName: '',
                lastName: '',
                externalId: '',
                mobile: '',
                email: '',
                sponsorCodeSentAt: '',
                sponsorCode: '',
                birthDate: '',
                source: '',
                sponsoredCount: -1,
                isPro: '',
                expectedSponsoredType: ''
            };

            statFormatted.giftUserFirstName = stat.giftUserStat.giftUserFirstName;
            statFormatted.giftUserLastName = stat.giftUserStat.giftUserLastName;
            statFormatted.giftUserEmail = stat.giftUserStat.giftUserEmail;
            if (stat.consumerStat.branchCode === '-1') statFormatted.branchCode = '';
            else statFormatted.branchCode = stat.consumerStat.branchCode;
            statFormatted.firstName = stat.consumerStat.firstName;
            statFormatted.lastName = stat.consumerStat.lastName;
            statFormatted.externalId = this.getExternalId(stat.consumerStat.externalId);
            statFormatted.mobile = stat.consumerStat.mobile;
            statFormatted.email = stat.consumerStat.email;
            statFormatted.sponsorCodeSentAt = this.convertDateUniversal(stat.sponsorCodeSentAt);
            statFormatted.sponsorCode = stat.sponsorCode;
            statFormatted.birthDate = this.convertDateUniversal(stat.consumerStat.birthDate);
            statFormatted.source = stat.source;
            statFormatted.sponsoredCount = stat.sponsoredCount;
            if (professionalEnabled) statFormatted.isPro = stat.consumerStat.consumerType === 'pro' ? 'oui' : 'non';
            statFormatted.expectedSponsoredType = stat.consumerStat.expectedSponsoredType;

            statsFormatted.push(statFormatted);
        }
        return statsFormatted;
    }

    public formatSponsorRewardedStats(stats: SponsorStat[], statsFormatted, anonymizeConsumersData, professionalEnabled?): any {
        for (const stat of stats) {
            for (const reward of stat.rewards) {
                if (anonymizeConsumersData) {
                    stat.consumerStat.firstName = '';
                    stat.consumerStat.lastName = '';
                    stat.consumerStat.email = '';
                    stat.consumerStat.mobile = '';
                    stat.consumerStat.birthDate = '';
                    stat.consumerStat.gender = '';
                }

                const statFormatted = {
                    giftUserFirstName: '',
                    giftUserLastName: '',
                    giftUserEmail: '',
                    branchCode: '',
                    branchName: '',
                    firstName: '',
                    lastName: '',
                    externalId: '',
                    mobile: '',
                    email: '',
                    sponsorCodeSentAt: '',
                    sponsorCode: '',
                    birthDate: '',
                    source: '',
                    rewardCreatedAt: '',
                    isPro: '',
                    expectedSponsoredType: ''
                };

                statFormatted.giftUserFirstName = stat.giftUserStat.giftUserFirstName;
                statFormatted.giftUserLastName = stat.giftUserStat.giftUserLastName;
                statFormatted.giftUserEmail = stat.giftUserStat.giftUserEmail;
                if (stat.consumerStat.branchCode === '-1') statFormatted.branchCode = '';
                else statFormatted.branchCode = stat.consumerStat.branchCode;
                statFormatted.firstName = stat.consumerStat.firstName;
                statFormatted.lastName = stat.consumerStat.lastName;
                statFormatted.externalId = this.getExternalId(stat.consumerStat.externalId);
                statFormatted.mobile = stat.consumerStat.mobile;
                statFormatted.email = stat.consumerStat.email;
                statFormatted.sponsorCodeSentAt = this.convertDateUniversal(stat.sponsorCodeSentAt);
                statFormatted.sponsorCode = stat.sponsorCode;
                statFormatted.birthDate = this.convertDateUniversal(stat.consumerStat.birthDate);
                statFormatted.source = stat.source;
                statFormatted.rewardCreatedAt = reward.createdAt;
                if (professionalEnabled) statFormatted.isPro = stat.consumerStat.consumerType === 'pro' ? 'oui' : 'non';
                statFormatted.expectedSponsoredType = stat.consumerStat.expectedSponsoredType;

                statsFormatted.push(statFormatted);
            }
        }
        return statsFormatted;
    }

    public formatSponsoredStats(stats: SponsoredStat[], statsFormatted, anonymizeConsumersData, professionalEnabled?): any {
        for (const stat of stats) {
            if (anonymizeConsumersData) {
                stat.consumerStat.firstName = '';
                stat.consumerStat.lastName = '';
                stat.consumerStat.email = '';
                stat.consumerStat.mobile = '';
                stat.consumerStat.birthDate = '';
                stat.consumerStat.gender = '';
                stat.sponsorEmail = '';
            }

            const statFormatted = {
                giftUserFirstName: '',
                giftUserLastName: '',
                giftUserEmail: '',
                branchCode: '',
                branchName: '',
                firstName: '',
                lastName: '',
                externalId: '',
                mobile: '',
                email: '',
                rewardedAt: '',
                sponsorCode: '',
                sponsorExternalId: '',
                sponsorEmail: '',
                sponsoredSponsorCode: '',
                amountRewarded: 0,
                birthDate: '',
                isPro: ''
            };

            statFormatted.giftUserFirstName = stat.giftUserStat.giftUserFirstName;
            statFormatted.giftUserLastName = stat.giftUserStat.giftUserLastName;
            statFormatted.giftUserEmail = stat.giftUserStat.giftUserEmail;
            if (stat.giftUserStat.branchCode === '-1') statFormatted.branchCode = '';
            else statFormatted.branchCode = stat.giftUserStat.branchCode;
            statFormatted.firstName = stat.consumerStat.firstName;
            statFormatted.lastName = stat.consumerStat.lastName;
            statFormatted.externalId = this.getExternalId(stat.consumerStat.externalId);
            statFormatted.mobile = stat.consumerStat.mobile;
            statFormatted.email = stat.consumerStat.email;
            statFormatted.rewardedAt = this.convertDateForSpreadsheet(stat.rewardedAt);
            statFormatted.sponsorCode = stat.sponsorCode;
            statFormatted.sponsorExternalId = this.getExternalId(stat.sponsorExternalId);
            statFormatted.sponsorEmail = stat.sponsorEmail;
            statFormatted.sponsoredSponsorCode = stat.sponsoredSponsorCode;
            statFormatted.amountRewarded = stat.amountRewarded;
            statFormatted.birthDate = this.convertDateUniversal(stat.consumerStat.birthDate);
            if (professionalEnabled) statFormatted.isPro = stat.consumerStat.consumerType === 'pro' ? 'oui' : 'non';

            statsFormatted.push(statFormatted);
        }
        return statsFormatted;
    }

    public formatStatsOperation(
        stats: any[], statsFormatted: StatsDisplay[], offers: Offer[], branches: Branch[], anonymizeConsumersData: boolean
    ): StatsDisplay[] {
        for (const stat of stats) {
            if (anonymizeConsumersData) {
                stat.consumerStat.firstName = '';
                stat.consumerStat.lastName = '';
                stat.consumerStat.email = '';
                stat.consumerStat.mobile = '';
                stat.consumerStat.birthDate = '';
            }

            const operationStat: StatsDisplay = {
                ...new StatsDisplay(),
                giftUserEmail: stat.giftUserStat.giftUserEmail,
                giftUserFirstName: stat.giftUserStat.giftUserFirstName,
                giftUserLastName: stat.giftUserStat.giftUserLastName,
                email: stat.consumerStat.email,
                externalId: this.getExternalId(stat.consumerStat.externalId),
                firstName: stat.consumerStat.firstName,
                lastName: stat.consumerStat.lastName,
                birthDate: stat.consumerStat.birthDate,
                branchCode: stat.giftUserStat.branchCode === '-1' ? '' : stat.giftUserStat.branchCode,
                id: stat.consumerStat.id,
                mobile: stat.consumerStat.mobile,
                amount: stat.amount,
                label: offers.find(o => +o.id === stat.offerId)?.name,
                rewardedAt: this.convertDateForSpreadsheet(stat.rewardedAt),
                expireAt: this.convertDateForSpreadsheet(stat.expireAt)
            };

            operationStat.branchName = branches?.find(s => s.externalId === operationStat.branchCode)?.name ?? '';

            statsFormatted.push(operationStat);
        }
        return statsFormatted;
    }

    public getInvitesBySettingsId(settingsId, filter): Observable<any> {
        return this.apiService.get(`/services/sponsorship/invitations/settings/${settingsId}`, [], filter);
    }

    public getExternalId(externalId: string): string {
        if (!externalId || externalId.indexOf(':') !== -1) return '';
        return externalId;
    }

    public getBranchName(stat, branches) {
        const branch = branches.find(b => b.externalId === stat.branchCode);
        return branch?.name || '';
    }
}

