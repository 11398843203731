import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConsumerSmall} from '../../../modules/consumers/models/consumerSmall.model';
import {GiftService} from '../../services/gift.service';
import {Consumer, MobileService, MsConsumersService, MsServicesSponsorshipService, SponsorshipUser} from '@isifid/core';
import {Router} from '@angular/router';
import {SponsorshipService} from '../../services/sponsorship.service';

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html'
})

export class SearchBarComponent implements OnInit {
    consumers: Array<ConsumerSmall> = [];
    loading = true;
    placeholder: string = 'Rechercher par N° de portable / email';
    searchSlug: string;
    showResult = false;

    // Allow to search consumer or sponsor only
    @Input() type: 'sponsor' | 'consumer' = 'consumer';
    // Send back the consumer
    @Output() consumerEvent = new EventEmitter<ConsumerSmall>();

    constructor(
        private readonly giftService: GiftService,
        private readonly msConsumersService: MsConsumersService,
        private readonly mobileService: MobileService,
        private readonly msServicesSponsorshipService: MsServicesSponsorshipService,
        private readonly router: Router,
        private readonly sponsorshipService: SponsorshipService
    ) {
    }

    ngOnInit(): void {
        if (this.type === 'sponsor') this.placeholder = 'Saisir le code parrain';
        this.loading = false;
    }

    dispatchSearch(): void {
        // Remove spaces from the search slug
        this.searchSlug = this.searchSlug.replace(/\s/g, '');
        if (!this.searchSlug) return;

        this.loading = true;
        this.showResult = false;
        // Init results
        this.consumers = [];

        if (this.type === 'sponsor' && this.placeholder === 'Saisir le code parrain') this.searchSponsorByCode();
        else this.searchConsumer(this.type === 'sponsor');
    }

    resetSearch() {
        this.showResult = false;
        this.consumers = [];
    }

    searchConsumer(sponsorOnly?: boolean): void {
        const data = {
            email: this.searchSlug,
            clientId: this.giftService.client.id,
            mobile: this.mobileService.formatMobile(this.searchSlug),
            status: 'active' // Only active consumers
        };

        // If the user search-bar by email
        // Else it's by mobile
        if (this.searchSlug.indexOf('@') !== -1) {
            data.email = this.searchSlug;
            delete data.mobile;
        } else delete data.email;

        // Search consumers based on criteria
        this.msConsumersService.getAll([], data).subscribe({
            next: (consumers) => {
                const consumersSmall = this.convertConsumersToConsumersSmall(consumers);
                // Find sponsors in the list of consumers
                if (sponsorOnly) this.findSponsor(consumersSmall);
                else {
                    this.consumers = consumersSmall;
                    // If only one consumer is found, redirect to the consumer page
                    consumers?.length === 1 ? this.endSearch(consumers[0].id) : this.endSearch();
                }
            }, error: () => {
                console.error('error while getting consumers');
                this.endSearch();
            }
        });
    }

    convertConsumersToConsumersSmall(consumers: Array<Consumer>): Array<ConsumerSmall> {
        const consumersSmall = [];
        consumers.forEach(c => {
            const tmp = new ConsumerSmall();
            Object.assign(tmp, c);
            consumersSmall.push(c);
        });
        return consumersSmall;
    }

    findSponsor(consumers: Array<ConsumerSmall>) {
        let i = 1;
        if (!consumers || consumers.length === 0) this.endSearch();
        consumers.forEach(consumer => {
            // Send a request to figure out if the consumer is a sponsor
            // ONLY add sponsors to the list
            this.sponsorshipService.getSponsorshipUserByConsumerId(consumer.id).subscribe({
                next: (sponsorshipUser: SponsorshipUser) => {
                    // If the consumer is a sponsor, add it to the list
                    if (sponsorshipUser?.code) {
                        consumer.sponsorCode = sponsorshipUser.code;
                        consumer.isSponsor = true;
                        this.consumers.push(consumer);
                    } else consumer.isSponsor = false;
                }, error: () => {
                    consumer.isSponsor = false;

                    if (i === consumers.length) this.endSearch();
                    else i++;
                }, complete: () => {
                    if (i === consumers.length) this.endSearch();
                    else i++;
                }
            });
        });
    }

    searchSponsorByCode(): void {
        // Get the sponsor by code
        this.msServicesSponsorshipService.getSponsorshipUserByCode(this.searchSlug).subscribe({
            next: (sponsor: SponsorshipUser) => {
                // Get the consumer with the sponsor consumerId
                this.msConsumersService.getConsumer(sponsor.consumerId).subscribe({
                    next: (consumer) => {
                        const consumerSmall = new ConsumerSmall();
                        Object.assign(consumerSmall, consumer);
                        this.consumers.push({...consumerSmall, isSponsor: true, sponsorCode: this.searchSlug});
                        this.endSearch();
                    }, error: () => {
                        console.error('error while getting consumer ' + sponsor.consumerId);
                        this.endSearch();
                    }
                });
            }, error: (err) => {
                if (err.status !== 404) console.error('error while getting sponsor by code');
                this.endSearch();
            }
        });
    }

    endSearch(consumerId?: string): void {
        // If only one consumer/sponsor is found : emit the sponsor else redirect to the consumer page
        if (this.consumers.length === 1) {
            if (consumerId) this.router.navigate([`/consumers/${consumerId}`]).then().catch();
            else this.consumerEvent.emit(this.consumers[0]);
        } else {
            this.showResult = true;
            this.loading = false;
        }
    }
}
