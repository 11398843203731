@if (!showResult && type === 'sponsor' && placeholder !== 'Saisir le code parrain') {
    <div class="row">
        <h3 class="mb-3">Données permettant d'identifier le parrain</h3>
    </div>
}
@if (!showResult) {
    <div class="row mb-1">
        <div class="col-12 col-md-10 col-lg-7 col-xl-5 mx-auto">
            <mat-form-field appearance="fill" class="my-3 col">
                <mat-label>
                    @if (placeholder !== 'Saisir le code parrain') {{{ placeholder }}}
                    @else {Code parrain}
                </mat-label>
                <input matInput
                       [(ngModel)]="searchSlug"
                       class="text-center"
                       id="searchSlug"
                       name="search"
                       type="text"/>
            </mat-form-field>
        </div>
    </div>
}

@if (!showResult && placeholder === 'Saisir le code parrain') {
    <div class="row">
        <div class="col mx-auto font-italic">
            Si le filleul a égaré ou oublié le code que<br> lui a transmis son parrain,
            <span data-cy="switch-search-type" (click)="placeholder = 'Adresse email ou N° de portable du parrain'" class="clickable text-purple">
                cliquez ici
            </span>
        </div>
    </div>
}

@if (!showResult && type === 'sponsor' && placeholder !== 'Saisir le code parrain') {
    <div class="row">
        <br>
        <div class="col-12 small font-italic">
            <span (click)="placeholder = 'Saisir le code parrain'"
                  class="clickable text-purple">Revenir à la recherche par code parrain</span>
        </div>
    </div>
}

@if (!showResult) {
    <div class="row mt-5">
        <div class="col mt-4 text-center">
            @if (!loading) {
                <button (click)="dispatchSearch()"
                        [disabled]="!searchSlug || searchSlug===''"
                        class="ms-3 btn-validate px-4 py-2"
                        data-cy="search">
                    Rechercher
                </button>
            } @else {
                <button class="ms-3" color="primary" disabled mat-raised-button>
                    <i aria-hidden="true" class="fas fa-spinner fa-spin"></i>
                </button>
            }
        </div>
    </div>
}

@if (showResult && type !== 'sponsor') {
    <div class="row mt-3">
        @if (consumers.length > 0) {
            <h3 class="text-dark-blue">Résultats de recherche</h3>
            @for (consumer of consumers; track consumer.id) {
                <div class="my-3">
                    <a [routerLink]="'/consumers/' + consumer.id">
                        {{ consumer.lastName }} {{ consumer.firstName }}
                        @if ((consumer.lastName !== '' || consumer.firstName !== '') && consumer.mobile) {<span> - </span>}
                        @if (consumer.mobile) {<span>{{ consumer.mobile }}</span>}
                    </a>
                </div>
            }
        } @else if (consumers.length === 0) {
            <div class="mb-5">
                <h3>Client non trouvé</h3>
            </div>
        }


        <div class="text-center mt-3">
            <button (click)="resetSearch()"
                    class="btn-validate ms-3 px-4 py-2"
                    data-cy="search">
                Nouvelle recherche
            </button>
        </div>
    </div>
}

@if (!loading && showResult && type === 'sponsor') {
    <div class="row mt-3">
        @if (consumers.length) {
            <h3 class="text-dark-blue">Résultats de recherche</h3>
            @for (consumer of consumers; track consumer.id) {
                <div class="my-3">
                    <a (click)="consumerEvent.emit(consumer)" class="clickable">
                        {{ consumer.lastName }} {{ consumer.firstName }}
                        @if ((consumer.lastName !== '' || consumer.firstName !== '') && consumer.mobile) {<span> - </span>}
                        @if (consumer.mobile) {<span>{{ consumer.mobile }}</span>}
                    </a>
                </div>
            }
        } @else {
            <div class="mb-5">
                @if (placeholder === 'Saisir le code parrain') {<h3>Code introuvable</h3>}
                @else {<h3>Client non trouvé</h3>}
            </div>
        }

        <div class="text-center mt-3">
            <button (click)="resetSearch()"
                    class="btn-validate ms-3 px-4 py-2"
                    data-cy="search">
                Nouvelle recherche
            </button>
        </div>
    </div>
}
