import {Injectable} from '@angular/core';
import {
    ConfigurationService,
    Consumer,
    GiftUser,
    MsConsumersService,
    MsServicesSponsorshipService,
    Offer,
    Operation,
    SponsorshipSettings,
    SponsorshipUser
} from '@isifid/core';
import {catchError, map, Observable, of, Subscription, switchMap, tap, throwError} from 'rxjs';
import {OperationsService} from './operations.service';
import {GiftService} from './gift.service';
import {GiftUserService} from './gift-user.service';
import {filter} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class SponsorshipService {
    hasSponsorship: boolean;
    hasSponsorshipOnline: boolean;
    operation: Operation;
    settings: SponsorshipSettings;
    readonly expectedSponsoredType = [
        'Parrainage crédit immobilier',
        'Parrainage EER jeune',
        'Les deux'
    ]
    private readonly operationTypeSponsorship = 2;
    private giftUser: GiftUser;
    private giftUserSubscription: Subscription;

    constructor(
        readonly configurationService: ConfigurationService,
        private readonly msConsumersService: MsConsumersService,
        private readonly msServicesSponsorshipService: MsServicesSponsorshipService,
        private readonly operationsService: OperationsService,
        private readonly giftService: GiftService,
        private readonly giftUserService: GiftUserService
    ) {
        this.giftUserSubscription = this.giftUserService.getGiftUser()
            .pipe(filter(s => !!s))
            .subscribe(s => this.giftUser = s);
    }

    initEntities(clientId: number): Observable<SponsorshipSettings[]> {
        return this.msServicesSponsorshipService.getAllSettings([], {clientId: clientId})
            .pipe(
                tap(settingsData => {
                    if (settingsData?.length > 0) {
                        this.settings = new SponsorshipSettings();
                        Object.assign(this.settings, settingsData[0]);
                    }
                    else {
                        this.settings = new SponsorshipSettings();
                    }
                }),
                catchError(error => {
                    console.error(`error while getting sponsorship settings by client ID ${clientId} =>`, error);
                    return throwError(() => error);
                })
            );
    }

    initSponsorship() {
        if (!this.settings) {
            this.operation = null;
            this.hasSponsorshipOnline = false;
            return;
        }

        const operations = this.operationsService.getOperations(true, false).filter(o => o.id === this.settings.operationId);

        // If an operation is found, we have a sponsorship operation in progress
        // If no operation is found, we remove any reference to the sponsorship
        if (operations.length) {
            this.operation = operations[0];
            this.hasSponsorship = this.operation.status === 'active';
            this.hasSponsorshipOnline = this.hasSponsorship && this.settings?.online;
        } else {
            this.settings = null;
            this.hasSponsorship = false;
            this.hasSponsorshipOnline = false;
        }
    }

    destroy() {
        this.settings = null;
        this.operation = null;
        this.hasSponsorship = null;
        this.hasSponsorshipOnline = null;
        this.giftUser = null;
        this.giftUserSubscription.unsubscribe();
    }

    getSponsorOffers(isPro: boolean): Observable<null | Offer[]> {
        return this.operationsService.getOffersByOperationId(this.operation.id)
            .pipe(
                map(offers => {
                    return offers.map(offer => {
                        if (offer.status.toLocaleLowerCase() === 'active' && offer.configuration) {
                            const isForProfessional = isPro && JSON.parse(offer.configuration)['forProfessional'] === true &&
                                JSON.parse(offer.configuration)['consumerType'] === 'sponsor';
                            const isNotForProfessional = !isPro && (!JSON.parse(offer.configuration)['forProfessional'] ||
                                JSON.parse(offer.configuration)['forProfessional'] !== true) && JSON.parse(offer.configuration)['consumerType'] === 'sponsor';

                            if (isForProfessional || isNotForProfessional) return offer;
                        }
                    })?.filter(s => !!s);
                }),
                catchError(error => {
                    console.error(`error occurs while getting sponsor offer by operation ID ${this.operation.id} =>`, error);
                    return of(null);
                })
            );
    }

    getSponsoredOffers(isPro: boolean): Observable<void | Offer[]> {
        return this.operationsService.getOffersByOperationId(this.operation.id)
            .pipe(
                map(offers => {
                    return offers.map(offer => {
                        if (offer.status.toLocaleLowerCase() === 'active' && offer.configuration) {
                            const isForProfessional = isPro && JSON.parse(offer.configuration)['forProfessional'] === true &&
                                JSON.parse(offer.configuration)['consumerType'] === 'sponsored';
                            const isNotForProfessional = !isPro && (!JSON.parse(offer.configuration)['forProfessional'] ||
                                JSON.parse(offer.configuration)['forProfessional'] !== true) && JSON.parse(offer.configuration)['consumerType'] === 'sponsored';

                            if (isForProfessional || isNotForProfessional) return offer;
                        }
                    })?.filter(s => !!s);
                }),
                catchError(error => {
                    console.error(`error occurs while getting sponsored offer by operation ID ${this.operation.id} =>`, error);
                    return of(null);
                })
            );
    }

    becomeSponsorshipUser(consumer: Consumer, sponsorshipUser: SponsorshipUser, sponsorType?: string): Observable<SponsorshipUser> {
        return !sponsorshipUser ?
            this.createSponsorshipUser(consumer, sponsorType) :
            this.upgradeSponsoredToSponsor(sponsorshipUser);
    }

    createSponsorshipUser(consumer: Consumer, typology?: string): Observable<SponsorshipUser> {
        consumer.clientId = Number(this.giftUser.clientId ?? this.settings.clientId);
        const data = {
            consumerId: consumer.id,
            clientId: consumer.clientId.toString(),
            source: 'gift'
        };

        // It will check the sponsorshipUser (and the consumer if needed)
        // Or will get the sponsorshipUser info if it already exists
        return this.msServicesSponsorshipService.createSponsorshipUser(data)
            .pipe(
                tap((s: SponsorshipUser) => {
                    if (!consumer.giftUserId && this.giftUser.id) consumer.giftUserId = this.giftUser.id;
                    if (!consumer.branchCode && this.giftUser.branchList?.length > 0) {
                        consumer.branchCode = this.giftService.convertBranchCodeToString(this.giftUser.branchList[0]);
                    }
                    // If the sponsor is a pro, we need to update the consumer.configuration with type
                    if (typology === 'pro') {
                        consumer.configuration = this.configurationService.addValue(consumer.configuration, 'typology', 'pro');
                    }
                    this.msConsumersService.updateConsumer(s.consumerId, consumer).subscribe();
                    this.sendSponsorAccess(s.consumerId).subscribe();
                }),
                catchError(error => {
                    console.error(`error while creating sponsor with consumer ID ${consumer.id}=>`, error);
                    return throwError(() => error);
                })
            );
    }

    upgradeSponsoredToSponsor(sponsorshipUser: SponsorshipUser): Observable<SponsorshipUser> {
        // Generate new sponsor code
        const data = {
            id: sponsorshipUser.id,
            code: (Math.random() * 10).toString(36).substring(8, 12).replace('I', 'K').replace('l', 'p'),
            source: 'gift'
        };

        return this.msServicesSponsorshipService.updateSponsorshipUser(sponsorshipUser.id, data)
            .pipe(
                switchMap((s: SponsorshipUser) => this.sendSponsorAccess(s.consumerId).pipe(map(() => s))),
                catchError(error => {
                    console.error(`error while updating sponsor with sponsor ID ${sponsorshipUser.id}=>`, error);
                    return throwError(() => error);
                })
            );
    }

    sendSponsorAccess(consumerId: number): Observable<void> {
        return this.msServicesSponsorshipService.sendSponsorshipUserAccess(consumerId)
            .pipe(
                catchError(error => {
                    console.error(`error while sending sponsor access with consumer ID ${consumerId} =>`, error);
                    return throwError(() => error);
                })
            );
    }

    getSponsorshipOperationDisabled(): Operation {
        const operations = this.operationsService.getOperations(true, false);
        // Returns first sponsorship operation
        return operations.find(o => o.operationType.id === this.operationTypeSponsorship && o.status === 'expired');
    }

    getSponsorshipUserByConsumerId(id: number): Observable<SponsorshipUser> {
        return this.msServicesSponsorshipService.getSponsorshipUserByConsumerId(id)
            .pipe(
                catchError(error => {
                    if (error.status === 404) return of(null);
                    else {
                        console.error(`error while getting sponsorship user by consumer id ${id} =>`, error);
                        return throwError(() => error);
                    }
                })
            );
    }
}
