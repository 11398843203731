<span class="dialog-panel-close" mat-dialog-close>&times;</span>
<mat-card class="p-0">
    <mat-card-header class="px-4 px-lg-5 pt-5 pb-3 text-white">
        <mat-card-title class="text-center fw-bold big pb-2">Vous n'êtes pas {{user.firstName}} ?</mat-card-title>
    </mat-card-header>
    @if (loading) {
        <mat-card-content class="p-5">
            <div class="py-5 my-4">
                <mat-spinner class="mx-auto" />
            </div>
        </mat-card-content>
    } @else if (accessSent && !loading) {
        <mat-card-content class="p-5">
            <h2 class="text-dark py-4 my-5" style="text-align: justify">
                Votre accès a bien été envoyé. Vous allez être déconnecté(e).
            </h2>
            <div class="row justify-content-center mt-4 mb-5">
                <div class="col text-center">
                    <button class="btn btn-black mt-2 text-nowrap" (click)="goToLogout()" data-cy="submit">
                        Me déconnecter
                    </button>
                </div>
            </div>
        </mat-card-content>
    } @else if (!accessSent && !loading) {
        <mat-card-content class="px-3 py-5">
            <div class="text-center mb-5">
                Pour recevoir votre lien personnel, merci de saisir votre adresse email ci-dessus. Chaque accès est unique
                et ne doit pas être partagé avec vos collègues.
            </div>
            <form (ngSubmit)="getAccess()" [formGroup]="formGroup" class="container mb-5 px-5 mx-auto text-center">
                <div class="row my-3">
                    <div class="col px-3">
                        <mat-form-field>
                            <mat-label>Email</mat-label>
                            <input matInput
                                   maxlength="60"
                                   class="text-center"
                                   formControlName="email"
                                   id="email"
                                   type="text"/>
                            <mat-error>
                                @if (!formGroup.controls.email.hasError('email')) {
                                    <span>
                                        Merci de saisir votre adresse email professionnelle et non celle de l'agence. Elle doit contenir un point entre votre prénom et nom.
                                    </span>
                                } @else {
                                    <span>
                                        Merci de saisir une adresse email valide.
                                    </span>
                                }
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row mt-4 mb-5">
                    <div class="col">
                        <button class="btn btn-black mt-2 text-nowrap"
                                data-cy="submit"
                                name="button"
                                type="submit">Recevoir le lien de connexion
                        </button>
                    </div>
                </div>
                @if (showError) {
                    <div class="text-center mt-5 text-danger">
                        Une erreur inconnue s'est produite. Merci de contacter notre assistance.
                    </div>
                }
            </form>
        </mat-card-content>
    }
</mat-card>
