export const environment = {
    production: true,
    apiUrl: 'https://api.isifid.com',
    cdnUrl: 'https://cdn.isifid.com',
    duration: 30000,
    domain: 'parrainage.isifid.com',
    version: 'c5c70d36',
    sentryUrl: 'https://99c419a2fca94b08a8f0850e77aee3db@o342465.ingest.sentry.io/5666977',
    emailRecipientsBusiness: ['Victoire <victoire@isifid.com>'],
    isifidDefaultRecipient: 'recompenses@isifid.com'
};
