import {Component, DestroyRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {GiftService} from '../../services/gift.service';
import {Title} from '@angular/platform-browser';
import {UIService} from '../../services/ui.service';
import {NavigationEnd, Router} from '@angular/router';
import {CapitalizePipe, Client, GiftSettings, UserCached, UserService} from '@isifid/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {SponsorshipService} from '../../services/sponsorship.service';
import {MatDialog} from '@angular/material/dialog';
import {
    DialogPersonalLinkComponent
} from '../../../modules/account/dialog-personal-link/dialog-personal-link.component';
import {
    DialogUpdateBranchCodeComponent
} from '../../../modules/account/dialog-update-branch-code/dialog-update-branch-code.component';
import {GiftUserService} from '../../services/gift-user.service';
import {filter, map} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    client: Client;
    giftSettings: GiftSettings;
    logoUrl: string;
    user: UserCached;
    branchCodes: string[] = [];
    path: string = '';

    @Output() toggle = new EventEmitter<boolean>();
    @ViewChild(MatMenuTrigger) accountMenu: MatMenuTrigger;

    constructor(
        public readonly giftService: GiftService,
        public readonly sponsorshipService: SponsorshipService,
        public readonly userService: UserService,
        private readonly router: Router,
        private readonly dialog: MatDialog,
        private readonly capitalizePipe: CapitalizePipe,
        private titleService: Title,
        private readonly giftUserService: GiftUserService,
        private readonly uiService: UIService,
        private readonly destroyRef: DestroyRef
    ) {
    }

    isAdminUrl(): boolean {
        if (this.router.url.indexOf('/admin') !== -1) return true;
        else return false;
    }

    ngOnInit(): void {
        this.path = this.router.url;
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => this.path = this.router.url.split('?')[0]);
        this.userService.user$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(s => this.user = s);
        this.client = this.giftService.client;
        this.giftSettings = this.giftService.settings;
        this.giftUserService.getGiftUser()
            .pipe(
                filter(s => !!s),
                map(s => ({...s, branchList: s.branchList ?? []}))
            )
            .subscribe(s => this.branchCodes = s.branchList.map(t => this.giftService.convertBranchCodeToString(t)));
        if (this.client && this.giftSettings) {
            this.setLogo();
            this.setTitlePage();
            this.uiService.setFavicon(this.giftSettings.networkName);
        }
    }

    openPersonalLinkDialog(): void {
        this.dialog.open(
            DialogPersonalLinkComponent,
            {
                data: this.user,
                maxWidth: 650,
                panelClass: 'dialog-panel'
            }
        );
    }

    openUpdatingBranchCodeDialog(): void {
        this.dialog.open(
            DialogUpdateBranchCodeComponent,
            {
                maxWidth: 650,
                panelClass: 'dialog-panel'
            }
        );
    }

    setTitlePage(): void {
        const clientName = this.capitalizePipe.transform(this.client.name);
        this.titleService.setTitle('Gift  ' + clientName);
    }

    setLogo(): void {
        this.logoUrl = environment.cdnUrl + '/static/' + this.client.logoFileName;
    }

    toggleSidenav(): void {
        if (this.giftService.isAccountComplete && this.path !== '/account/favorite?force=true') this.uiService.isSidenavOpened = !this.uiService.isSidenavOpened;
    }

    public canGoToHome(): boolean {
        this.path = this.router.url;
        return this.path !== '/home' && this.giftService.isAccountComplete && this.path !== '/account/favorite?force=true';
    }

    public goToHome(): void {
        if (this.canGoToHome()) this.router.navigate(['/home']).then();
    }
}
