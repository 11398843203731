import {Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {ConfigurationService, CustomRewardField, MsServicesOperationsService} from '@isifid/core';

@Injectable({
    providedIn: 'root'
})
export class CustomFieldService {
    constructor(
        private readonly configurationService: ConfigurationService,
        private readonly msServicesOperationsService: MsServicesOperationsService
    ) {
    }

    public getCustomFieldsFromConfiguration(configuration: string): CustomRewardField[] {
        let customFields = [];
        if (this.configurationService.isJson(configuration)) {
            customFields = this.configurationService.getValueByKey(configuration, 'formFields') as CustomRewardField[];
            if (Array.isArray(customFields)) customFields.sort((a, b) => a.order - b.order);
            else customFields = [];
        }
        return customFields;
    }

    public addCustomField(field: CustomRewardField & {offerId?: number}, configuration: string, operationId: number): Observable<CustomRewardField[]> {
        const offerId = field.offerId;
        delete field.offerId;
        const customRewardFormFields = [...this.getCustomFieldsFromConfiguration(configuration), field];
        const payload = {configuration: JSON.stringify({formFields: customRewardFormFields})};

        return this.msServicesOperationsService.updateOffer(operationId, offerId, payload)
            .pipe(map(() => customRewardFormFields));
    }

    public updateCustomField(
        field: CustomRewardField & {offerId?: number},
        newField: CustomRewardField,
        configuration: string,
        operationId: number
    ): Observable<CustomRewardField[]> {
        const offerId = field.offerId;
        delete field.offerId;
        const customRewardFormFields = this.getCustomFieldsFromConfiguration(configuration)
            .map(s => JSON.stringify(s) === JSON.stringify(field) ? newField : s);
        const payload = {configuration: JSON.stringify({formFields: customRewardFormFields})};

        return this.msServicesOperationsService.updateOffer(operationId, offerId, payload)
            .pipe(map(() => customRewardFormFields));
    }

    public deleteCustomField(field: CustomRewardField & {offerId?: number}, configuration: string, operationId: number): Observable<any> {
        const offerId = field.offerId;
        delete field.offerId;
        const customRewardFormFields = this.getCustomFieldsFromConfiguration(configuration)
            .filter(s => JSON.stringify(s) !== JSON.stringify(field));
        const payload = {configuration: JSON.stringify({formFields: customRewardFormFields})};

        return this.msServicesOperationsService.updateOffer(operationId, offerId, payload)
            .pipe(map(() => customRewardFormFields));
    }
}
