import {Injectable} from '@angular/core';
import {CacheService, CrispService, GiftUser, MsCoreService, MsServicesGiftService, UserService} from '@isifid/core';
import {BehaviorSubject, catchError, map, Observable, tap, throwError} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class GiftUserService {
    private giftUser: BehaviorSubject<GiftUser> = new BehaviorSubject<GiftUser>(null);

    constructor(
        private readonly cacheService: CacheService,
        private readonly crispService: CrispService,
        private readonly msCoreService: MsCoreService,
        private readonly msServicesGiftService: MsServicesGiftService,
        private readonly userService: UserService,
        private readonly _snackBar: MatSnackBar
    ) {
        this.initFromCache();
    }

    destroy(): void {
        this.giftUser.next(null);
    }

    initFromCache(): void {
        this.giftUser.next(this.cacheService.getContent('giftUser'));
    }

    init(uuid: string): Observable<GiftUser> {
        return this.msServicesGiftService.getGiftUserByUuid(uuid)
            .pipe(tap(s => this.giftUser.next(s)));
    }

    getGiftUser(): Observable<GiftUser> {
        return this.giftUser.asObservable();
    }

    updateGiftUserBranchList(giftUser: GiftUser, branchList: number[]): Observable<void> {
        return new Observable<void>((o) => {
            this.msServicesGiftService.updateGiftUser({...giftUser, branchList}).subscribe({
                next: () => {
                    this._snackBar.open('Code agence ou point de vente mis à jour', 'X');
                    // Only save giftUser in cache if current user is the giftUser
                    // Not saving giftUser in cache if hq impersonate
                    if (this.userService.getUser().uuid === giftUser.uuid) {
                        this.init(giftUser.uuid).subscribe({
                            next: () => {
                                o.next();
                                o.complete();
                            },
                            error: () => o.error()
                        });
                    } else {
                        o.next();
                        o.complete();
                    }
                },
                error: (error) => {
                    this._snackBar.open('Une erreur s\'est produite', 'X');
                    console.error(`Error while updating giftUser with uuid ${giftUser.uuid} => `, error);
                    o.error();
                }
            });
        });
    }

    updateUser(user, data): Observable<void> {
        return new Observable<void>((o) => {
            this.msCoreService.updateUser(user.uuid, data).subscribe({
                next: () => {
                    // Only update if the same user
                    if (this.userService.getUser().uuid === user.uuid) this.userService.updateUserFromObject(user);
                    this.crispService.initUser(user).subscribe();
                    this.crispService.setValue('session:data', ['branchList', this.giftUser.value.branchList]).subscribe();

                    this._snackBar.open('Compte mis à jour', 'X');
                    o.next();
                    o.complete();
                },
                error: (error) => {
                    this._snackBar.open('Une erreur s\'est produite', 'X');
                    console.error(`Error while updating user with uuid ${user.uuid} => `, error);
                    o.error();
                }
            });
        });
    }


    getGiftUsersByClientId(clientId: string): Observable<GiftUser[]> {
        return this.msServicesGiftService.getAllGiftUsers([], {clientId: clientId})
            .pipe(
                // Return empty array if null comes from backend
                map(s => s || []),
                catchError(error => {
                    console.error(`Error while getting all giftUsers with clientId ${clientId} => `, error);
                    return throwError(() => error);
                })
            );
    }

    getGiftUsersByBranch(branchCode: number): Observable<GiftUser[]> {
        return this.msServicesGiftService.getAllGiftUsers([], {clientId: this.giftUser.value.clientId})
            .pipe(
                // Return filtered giftUsers by branchList of giftUser
                map(giftUsers => giftUsers ? giftUsers.filter(giftUser => giftUser.branchList?.includes(branchCode)) : []),
                catchError(error => {
                    console.error(`Error while getting all giftUsers with branch code ${branchCode} => `, error);
                    return throwError(() => error);
                })
            );
    }

    getGiftUsersByManagerUuid(uuid: string, branchList?: number[]): Observable<GiftUser[]> {
        return this.msServicesGiftService.getGiftUsersByManagerUuid(uuid)
            .pipe(
                // Return filtered giftUsers by certain branchList or giftUser's branchList
                map(s => s.filter(t => t.branchList.some(f => (branchList || this.giftUser.value.branchList).includes(f)))),
                catchError(error => {
                    console.error(`Error while getting all giftUsers with manage uuid ${uuid}  => `, error);
                    return throwError(() => error);
                })
            );
    }
}
