import {Injectable} from '@angular/core';
import {MsServicesOperationsService, Offer, Operation} from '@isifid/core';
import {catchError, Observable, of} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OperationsService {
    public operations: Operation[] = [];

    constructor(
        private readonly msServicesOperationsService: MsServicesOperationsService
    ) {
    }

    init(clientId): Observable<any> {
        return new Observable(o => {
            this.msServicesOperationsService.getOperationsByClient(clientId).subscribe({
                next: (operations: Array<Operation>) => {
                    this.operations = this.sortOperations(operations);
                    o.next();
                    o.complete();
                }, error: () => {
                    console.error('error while getting client operations' + clientId);
                    o.error();
                }
            });
        });
    }

    sortOperations(operations: Array<Operation>): Array<Operation> {
        const platformOperationsWithBugdet = operations.filter(o => o.budget && o.consumerCreationProcess === 'platform');
        const platformOperationsWithoutBugdet = operations.filter(o => !o.budget && o.consumerCreationProcess === 'platform');
        const nonPlatformOperations = operations.filter(o => o.consumerCreationProcess !== 'platform');

        operations = [];

        platformOperationsWithBugdet.sort((a, b) => a.name.localeCompare(b.name));
        platformOperationsWithBugdet.forEach(o => operations.push(o));
        platformOperationsWithoutBugdet.sort((a, b) => a.name.localeCompare(b.name));
        platformOperationsWithoutBugdet.forEach(o => operations.push(o));
        nonPlatformOperations.sort((a, b) => a.name.localeCompare(b.name));
        nonPlatformOperations.forEach(o => operations.push(o));
        return operations;
    }

    // By default, get all active operations for gift without sponsorship
    // Sort by name by default
    getOperations(withSponsorship: boolean = false, activeOnly: boolean = true, platformOnly: boolean = true, withInternal: boolean = false): Operation[] {
        return this.operations.filter(o => {
            if (!withSponsorship && o.operationType.name === 'SPONSORSHIP') return false;
            if (!withInternal && o.operationType.name === 'INTERNAL') return false;
            if (activeOnly && o.status !== 'active') return false;
            if (platformOnly && o.consumerCreationProcess !== 'platform') return false;
            return true;
        }).sort((a, b) => a.name.localeCompare(b.name));
    }

    getOffersByOperationId(operationId): Observable<Offer[]> {
        return this.msServicesOperationsService.getOffersByOperation(operationId)
            .pipe(
                catchError(() => {
                    console.error('error while getting offers by operation id ' + operationId);
                    return of([]);
                })
            );
    }
}
