import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {OperationsService} from './operations.service';
import {GiftService} from './gift.service';

@Injectable({providedIn: 'root'})
export class UIService {
    public rewardWithRegular: boolean;
    public rewardWithPremium: boolean;
    public rewardWithPlatinum: boolean;
    public rewardWithCash: boolean;
    public rewardWithCashOnly: boolean;
    public isSidenavOpened: boolean;

    constructor(
        private readonly operationsService: OperationsService,
        private readonly giftService: GiftService
    ) {
    }

    public init(): void {
        // True if operation other than sponsorship
        this.rewardWithRegular = !!this.operationsService.getOperations().find(o => o.rewardTypeId < 2);
        this.rewardWithPremium = !!this.operationsService.getOperations(true).find(o => o.rewardTypeId === 2);
        this.rewardWithPlatinum = !!this.operationsService.getOperations(true).find(o => o.rewardTypeId === 3);
        this.rewardWithCash = !!this.operationsService.getOperations(true).find(o => o.rewardTypeId === 10);
        this.rewardWithCashOnly = !this.rewardWithRegular && !this.rewardWithPremium && !this.rewardWithPlatinum && this.rewardWithCash;
    }

    public destroy(): void {
        this.rewardWithRegular = null;
        this.rewardWithPremium = null;
        this.rewardWithCash = null;
    }


    public getRewardType(rewardTypeId: number): string {
        switch (rewardTypeId) {
            case 1:
                return 'Regular';
            case 2:
                return 'Premium';
            case 3:
                return 'Platinium';
            case 10:
                return 'Euros';
            case 11:
                return 'Remise sur vos produits';
            case 12:
                return 'Autres cartes cadeaux';
            case 13:
                return 'Autre';
        }
        return null;
    }

    public setFavicon(name) {
        const favIcon: HTMLLinkElement = document.querySelector('#appIcon');
        let faviconUrl;
        switch (name) {
            case 'bp':
            case 'ca':
            case 'ce':
                faviconUrl = environment.cdnUrl + '/common/img/favicon/' + name + '.png';
                break;
            case 'novabank':
            default:
                faviconUrl = environment.cdnUrl + '/common/img/favicon/' + name + '.ico';
                break;
        }
        favIcon.href = faviconUrl;
    }

    public getProductsRegular() {
        const cdnUrl = environment.cdnUrl + '/gift/img/';
        return [
            {
                name: 'Smartbox',
                link: 'https://www.smartbox.com/',
                price: 20,
                imageBackground: 'url(\'' + cdnUrl + 'products/smartbox.jpg\')'
            },
            {
                name: 'Tick&Box',
                link: 'https://www.tickandbox.net/',
                price: 30,
                imageBackground: 'url(\'' + cdnUrl + 'products/tickbox.jpg\')'
            },
            {
                name: 'L’Occitane en provence',
                link: 'https://fr.loccitane.com/',
                price: 10,
                imageBackground: 'url(\'' + cdnUrl + 'products/loccitane.jpg\')'
            },
            {
                name: 'Aquarelle',
                link: 'https://www.aquarelle.com/',
                price: 25,
                imageBackground: 'url(\'' + cdnUrl + 'products/red_roses.jpg\')'
            },
            {
                name: 'Cheerz',
                link: 'https://www.cheerz.com/',
                price: 20,
                imageBackground: 'url(\'' + cdnUrl + 'products/cheerz.jpg\')'
            },
            {
                name: 'En voiture Simone',
                link: 'https://www.envoituresimone.com/',
                price: 50,
                imageBackground: 'url(\'' + cdnUrl + 'products/voiture.jpg\')'
            },
            {
                name: 'SO FOOT',
                link: 'https://www.sofoot.com/',
                price: 40,
                imageBackground: 'url(\'' + cdnUrl + 'products/sofoot.jpg\')'
            },
            {
                name: 'DirectOptic',
                link: 'https://www.direct-optic.fr/',
                price: 40,
                imageBackground: 'url(\'' + cdnUrl + 'products/directoptic.jpg\')'
            }];
    }

    public getProductsPremium() {
        const cdnUrl = environment.cdnUrl + '/gift/img/';
        return [
            {
                name: 'Smartbox',
                link: 'https://www.smartbox.com/',
                price: 49,
                price2: 79,
                imageBackground: 'url(\'' + cdnUrl + 'products/smartbox.jpg\')'
            },
            {
                name: 'Aquarelle',
                link: 'https://www.aquarelle.com/',
                price: 50,
                imageBackground: 'url(\'' + cdnUrl + 'products/red_roses.jpg\')'
            },
            {
                name: 'Cheerz',
                link: 'https://www.cheerz.com/',
                price: 50,
                imageBackground: 'url(\'' + cdnUrl + 'products/cheerz.jpg\')'
            },
            {
                name: 'En voiture Simone',
                link: 'https://www.envoituresimone.com/',
                price: 50,
                imageBackground: 'url(\'' + cdnUrl + 'products/voiture.jpg\')'
            },
            {
                name: 'DirectOptic',
                link: 'https://www.direct-optic.fr/',
                price: 80,
                imageBackground: 'url(\'' + cdnUrl + 'products/directoptic.jpg\')'
            }];
    }

    public getVariables(settings) {
        return [
            {
                var: '${clientName}',
                infos: 'Nom du client',
                value: this.giftService.client.name
            },
            {
                var: '${clientLegalNotice}',
                infos: 'Mentions légales du client',
                value: this.giftService.client.legalNotices
            },
            {
                var: '${clientLogoUrl}',
                infos: 'URL du logo du client',
                value: this.giftService.client.logoUrl
            },
            {
                var: '${sponsorAmount}',
                infos: 'Montant de la récompense parrain',
                value: settings.sponsorAmount
            },
            {
                var: '${sponsoredAmount}',
                infos: 'Montant de la récompense filleul',
                value: settings.sponsoredAmount
            }
        ];
    }
}
