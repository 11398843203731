import {Injectable} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {
    AuthenticationService,
    Budget,
    CacheService,
    Client,
    ConfigurationService,
    GiftConfiguration,
    GiftNetworkVariables,
    GiftSettings, GiftUser,
    HierarchicalLevel,
    MsClientsService,
    MsServicesGiftService,
    UserService
} from '@isifid/core';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {GiftUserService} from './gift-user.service';
import {filter} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class GiftService {
    budgets: Budget[];
    client: Client;
    giftNetworkVariables: GiftNetworkVariables;
    hierarchicalLevels: HierarchicalLevel[];
    isAccountComplete: boolean;
    isManagerAccountComplete: boolean;
    settings: GiftSettings;
    public giftConfiguration: string | null = null;
    private giftUser: GiftUser;

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly cacheService: CacheService,
        private readonly configurationService: ConfigurationService,
        private readonly http: HttpClient,
        private readonly giftUserService: GiftUserService,
        private readonly msClientsService: MsClientsService,
        private readonly msServicesGiftService: MsServicesGiftService,
        private readonly router: Router,
        private readonly userService: UserService
    ) {
        this.giftUserService.getGiftUser().pipe(filter(s => !!s)).subscribe(s => this.giftUser = s);
        this.checkIfAccountComplete();
    }

    destroy() {
        this.client = null;
        this.settings = null;
        this.giftNetworkVariables = null;
        this.hierarchicalLevels = null;
        this.budgets = [];
        this.giftConfiguration = null;
    }

    init(clientId): Observable<any> {
        return forkJoin([
            this.initClient(clientId),
            this.initSettingsByClientId(clientId),
            this.initHierarchicalLevels(clientId),
            this.initBudgets(clientId)
        ]);
    }

    initClient(clientId): Observable<any> {
        return new Observable(o => {
            this.msClientsService.getClient(clientId).subscribe({
                next: (clientData) => {
                    this.client = clientData;
                    this.cacheService.addPermanentContent('client', this.client);
                    o.next();
                    o.complete();
                }, error: () => {
                    console.error('Error while getting client ' + clientId);
                    o.error();
                }
            });
        });
    }

    initSettingsByClientId(clientId): Observable<any> {
        return new Observable(o => {
            this.msServicesGiftService.getSettingsByClientId(clientId).subscribe({
                next: (settingsData) => {
                    this.settings = settingsData ?? new GiftSettings();
                    this.initGiftConfiguration();
                    this.cacheService.addPermanentContent('gift_settings', this.settings);
                    this.initGiftNetworkVariables(this.settings.networkName).subscribe({
                        next: () => {
                            o.next();
                            o.complete();
                        }, error: () => {
                            console.error('Error while init gift network variables');
                            o.error();
                        }
                    });
                }, error: () => {
                    console.error('Error while getting Gift settings for client ' + clientId);
                    o.error();
                }
            });
        });
    }

    private initGiftConfiguration() {
        const configuration = new GiftConfiguration();
        Object.assign(configuration, JSON.parse(this.settings.configuration || '{}'));
        this.giftConfiguration = JSON.stringify(configuration);
        delete this.settings.configuration;
    }

    initGiftNetworkVariables(networkName): Observable<any> {
        return new Observable(o => {
            if (!networkName || networkName === '') {
                o.next();
                o.complete();
            }
            const jsonFile = `assets/data/${networkName}.json`;

            this.http.get(jsonFile).subscribe({
                next: (networkVariablesFromFiles: GiftNetworkVariables) => {
                    this.giftNetworkVariables = networkVariablesFromFiles;
                    this.cacheService.addPermanentContent('gift_network_variables', this.giftNetworkVariables);
                    // If an url was defined, make sure we use it - only for production
                    if (environment.production && this.giftNetworkVariables.url &&
                        this.giftNetworkVariables.url !== '' && window.location.origin.indexOf(this.giftNetworkVariables.url) === -1) {
                        o.error();
                    }

                    o.next();
                    o.complete();
                }, error: () => {
                    console.error('Error while getting jsonFile');
                    o.error();
                }
            });
        });
    }

    initHierarchicalLevels(clientId): Observable<any> {
        return new Observable(o => {
            this.msServicesGiftService.getHierarchicalLevelsByClientId(clientId).subscribe({
                next: (hierarchicalLevels) => {
                    hierarchicalLevels.sort((a, b) => a.position - b.position);
                    this.hierarchicalLevels = hierarchicalLevels;
                    this.cacheService.addPermanentContent('gift_hierarchical_levels', this.hierarchicalLevels);

                    o.next();
                    o.complete();
                }, error: () => {
                    console.error('Error while init gift hierarchical levels');
                    o.error();
                }
            });
        });
    }

    getSuperiorHierarchicalLevel(position): HierarchicalLevel {
        const level = this.hierarchicalLevels.find(l => l.position === (position + 1));
        if (level) return level;
        return null;
    }

    initBudgets(clientId): Observable<any> {
        return new Observable(o => {
            this.msServicesGiftService.getBudgetsByClientId(clientId).subscribe({
                next: (budgets) => {
                    this.budgets = budgets.filter(b => b.status === 'active');
                    o.next();
                    o.complete();
                }, error: () => {
                    console.error('Error while init gift budgets');
                    o.error();
                }
            });
        });
    }

    // The branchCode needs to be 5 chars long, even if filled with 0 at the beginning
    getFirstBranchParsedAsString(branchList: number[]): string {
        if (!branchList || branchList.length === 0 || !branchList[0]) return '';

        if (branchList[0] < 0) return branchList[0].toString();

        let branchCode = branchList[0].toString();
        while (branchCode.length < 5) branchCode = '0' + branchCode;
        return branchCode;
    }

    convertBranchCodeToString(branchCode: number): string {
        if (!branchCode && branchCode !== 0) return '';
        let branchCodeParsed = branchCode.toString().replace(/\s/g, '');
        while (branchCodeParsed.length < 5) branchCodeParsed = '0' + branchCodeParsed;
        return branchCodeParsed;
    }

    checkIfAccountComplete(): void {
        if (!this.authenticationService.isAuthenticated()) {
            this.isAccountComplete = false;
            this.isManagerAccountComplete = false;
            return;
        }

        // No need for a complete profile when Isifid or HQ
        if (this.userService.hasRole('GIFT_ISIFID') || this.userService.hasRole('GIFT_HQ')) {
            this.isAccountComplete = true;
            this.isManagerAccountComplete = true;
            return;
        }

        // Now that basics have been checked above
        // We can check manager account
        this.checkIfManagerAccountComplete();

        const user = this.userService.getUser();
        if (!user) {
            // Make sure we have user if not logout
            this.router.navigate(['/logout']).then();
        } else if (!user.firstName || user.firstName === '' || !user.lastName || user.lastName === '') {
            // Make sure we have first name / last name
            this.isAccountComplete = false;
        } else if (this.userService.hasRole('GIFT_ADVISOR') && (!this.giftUser?.branchList?.length)) {
            // Need at least one branch code for advisors
            this.isAccountComplete = false;
        } else this.isAccountComplete = true;
    }

    checkIfManagerAccountComplete(): void {
        if (this.userService.hasRole('GIFT_MANAGER')
            && this.giftUser?.branchList?.length < 1) this.isManagerAccountComplete = false;
        else this.isManagerAccountComplete = true;
    }

    getConfigurationValue(value): any {
        return this.configurationService.getValueByKey(this.giftConfiguration, value);
    }
}


