    <div class="text-end my-4 py-4 px-4">
        <button class="close-button" mat-icon-button (click)="toggleSidenav()" >
            <i aria-hidden="true" class="fas fa-times"></i>
        </button>
    </div>

    @if (router.url.includes('/admin')) {
        <ul class="menu-list pe-4">
            @for (menu of menus; track menu.name; let i = $index;) {
                <li [class.active]="menu.active">
                    @if (menu.url) {
                        <a class="menu" [routerLink]="menu.url">
                            <i [class]="menu.iconClass"></i>
                            {{ menu.name }}
                        </a>
                    } @else {
                        <div class="menu" (click)="toggle(i)">
                            <i [class]="menu.iconClass"></i>
                            {{ menu.name }}
                            <i class="fa fa-chevron-down"></i>
                        </div>
                    }
                    <ul class="submenu" [style.height]="menu.active ? 'auto' : 0">
                        @for (submenu of menu.submenu; track submenu) {
                            <li>
                                <a [routerLink]="submenu.url" routerLinkActive="active">{{ submenu.name }}</a>
                            </li>
                        }
                    </ul>
                </li>
            }

            <li>
                <label class="menu-label">
                    <div class="menu">
                        <i class="fa fa-envelope"></i>
                        Envoyer un fichier
                    </div>
                    <input (change)="sendFile($event.target)" hidden type="file" />
                </label>
            </li>
        </ul>
    } @else {
        <div class="pe-4">
            <div class="clickable my-3 py-1 px-4 mat-h1 hover-purple fw-bold lh-base"
                 routerLink="/">Accueil
            </div>

            @if (sponsorshipService.hasSponsorship && !userService.hasRole('GIFT_DIRECTOR')) {
                <div [routerLink]="['/sponsorship', 'sponsor', 'invite']"
                     data-cy="sponsor-invite"
                     class="clickable my-3 py-1 px-4 mat-h1 hover-purple fw-bold lh-base">
                    Inscrire un client<br> sur la plateforme<br> de recommandation
                </div>
                <div [routerLink]="['/sponsorship', 'sponsored', 'check']"
                     class="clickable my-3 py-1 px-4 mat-h1 hover-purple fw-bold lh-base">
                    Saisir le code parrain<br> présenté par un filleul
                </div>
            }


            <div class="clickable my-3 py-1 px-4 mat-h1 hover-purple fw-bold"
                 routerLink="/account/credits">
                Mon budget Récompenses
            </div>

            <div class="clickable my-3 py-1 px-4 mat-h1 hover-purple fw-bold" routerLink="/consumers/search">
                Rechercher un client
            </div>

            <div class="clickable my-3 py-1 px-4 mat-h1 hover-purple fw-bold"
                 routerLink="/consumers/tracking">
                Suivi des récompenses
            </div>

            <div class="clickable my-3 py-1 px-4 mat-h1 hover-purple fw-bold"
                 routerLink="/sponsorship/tracking">
                Suivi parrainage
            </div>

            @if (!uiService.rewardWithCash) {
                <div class="clickable my-3 py-1 px-4 mat-h1 hover-purple fw-bold"
                     routerLink="/gift-cards">
                    Découvrir les récompenses
                </div>
            }

            <div class="clickable my-3 py-1 px-4 mat-h1 hover-purple fw-bold" routerLink="/support">
                Assistance
            </div>
        </div>
    }
